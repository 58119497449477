body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.ra-input table {
  width: 100% !important;
}

.invitation__dialog__url {
  border: none;
  width: 100%;
  font-size: 14px;
  font-family: monospace;
  white-space: pre;
  margin: 10px 0;
}

.period__field__paid {
  color: #006600;
}

.period__field__unpaid {
  color: #ff0000;
}
.period_due {
  color: #ff0000;
}
.period_warning {
  color: #ff9800;
}

.period_due,
.period_warning {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.period_due:after,
.period_warning:after {
  font-size: 18px;
  margin-left: 1em;
}
.period_due:after {
  content: "∅";
}

.period_warning:after {
  content: "⚠";
}

.period__licenses__limit {
  color: #ff0000;
}
