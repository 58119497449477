
.CsvImportInput {
  display: flex;
  width: 100%;
  height: 200px;
  border: solid 1px #aaa;
  padding: 10px;
  overflow: scroll;
}

.CsvImportInput__Dropzone {
  display: flex;
  width: 100%;
  height: 200px;
  border: solid 1px #aaa;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.CsvImportInput__Dropzone--active {
  background: #eee;
}


.CsvImportInput td {
  padding: 5px 10px;
}

.CsvImportInput tr:nth-child(odd) {
  background: #eee;
}


.CsvImportInput table {
  border-collapse: collapse;
}