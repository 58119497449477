
.JsonField {
  width: 512px;
  display: flex;
  flex-direction: column;
}

.JsonField > .JsonFieldValue  {
  display: flex;
  flex-direction: row;
  padding: 4px;
}

.JsonField > .JsonFieldValue  > span{
  flex: 1;
}

.JsonField > .JsonFieldValue  > span:first-child {
  opacity: 0.5;
}

.JsonField > .JsonFieldValue:nth-child(odd) {
  background: #fafafa;
}